import React, { PureComponent } from 'react';
import DarkModeToggle from "react-dark-mode-toggle";
import { Link as ScrollLink } from "react-scroll";

class Navbar extends PureComponent {

    handleDarkModeToggle = (isChecked, event) => {
        //event.preventDefault();

        this.props.onUpdate(isChecked);
    };

    render() {
        return (
            <header className="bg-sky-100 dark:bg-gray-800 md:sticky top-0 z-50">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <ScrollLink
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={750}
                        className="link title-font font-medium dark:text-white mb-4 md:mb-0 ml-3 text-xl cursor-pointer hover:text-gray-700 dark:hover:text-gray-300"
                        activeClass="active"
                    >
                        Brandon Clark
                    </ScrollLink>
                    <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:dark:border-gray-700	flex flex-wrap items-center text-base justify-center">
                        <ScrollLink
                            to="projects"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={750}
                            className="mr-5 hover:dark:text-white cursor-pointer"
                        >
                            Projects
                        </ScrollLink>
                        <ScrollLink
                            to="skills"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={750}
                            className="mr-5 hover:dark:text-white cursor-pointer"
                        >
                            Skills
                        </ScrollLink>
                        <ScrollLink
                            to="testimonials"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={750}
                            className="mr-5 hover:dark:text-white cursor-pointer"
                        >
                            Testimonials
                        </ScrollLink>
                    </nav>
                    <ScrollLink
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={750}
                        className="inline-flex items-center cursor-pointer dark:bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:dark:bg-gray-700 rounded text-base mt-4 md:mt-0"
                    >
                        Contact Me
                    </ScrollLink>

                    <DarkModeToggle
                        onChange={this.handleDarkModeToggle}
                        checked={this.props.isDarkMode}
                        size={50}
                    />
                </div>
            </header>
        );
    }
}

export default Navbar;